angular
  .module('app')
  .service('tallerAmigoRetailersService', tallerAmigoRetailersService);

/** @ngInject */
function tallerAmigoRetailersService($log, $q, getTallerAmigoRetailersFactory) {
  var self = {
    // OBJECTS
    tallerAmigoRetailer: {},

    // FLAGS
    isGettingTallerAmigoRetailer: false,

    // METHODS
    getTallerAmigoRetailer: function (cityId, retailerCode) {
      if (self.isGettingTallerAmigoRetailer) {
        return;
      }

      self.isGettingTallerAmigoRetailer = true;

      var defer = $q.defer();

      getTallerAmigoRetailersFactory
        .get({
          cidade: cityId,
          retailerCode: retailerCode
        })
        .$promise
        .then(function (response) {
          if (response) {
            self.tallerAmigoRetailer = {
              id: response.id,
              corporateName: response.corporateName,
              cuit: response.cuit,
              email: response.email,
              phone: response.phone,
              sap: response.sap,
              address: {
                city: response.address.city,
                latitude: response.address.latitude,
                longitude: response.address.longitude,
                neighborhood: response.address.neighborhood,
                number: response.address.number,
                state: response.address.state,
                street: response.address.street
              }
            };
          }

          self.isGettingTallerAmigoRetailer = false;

          defer.resolve(self.tallerAmigoRetailer);
        })
        .catch(function (reason) {
          $log.error('tallerAmigoRetailersService.getTallerAmigoRetailer', reason);

          self.isGettingTallerAmigoRetailer = false;

          defer.reject(reason);
        });

      return defer.promise;
    }
  };

  return self;
}
