angular
  .module('app')
  .config(tallerAmigoRoutes);

/** @ngInject */
function tallerAmigoRoutes($stateProvider) {
  $stateProvider.state('tallerAmigo', {
    url: '/taller-amigo',
    component: 'tallerAmigo',
    resolve: {
      currentAuth: ['firebaseAuthFactory', function (firebaseAuthFactory) {
        return firebaseAuthFactory.$requireSignIn();
      }]
    }
  });
}
