angular
  .module('app')
  .service('batteryService', batteryService);

/** @ngInject */
function batteryService($log, $q, toaster, getBatteryFactory) {
  var self = {
    // OBJECTS
    selectedBattery: {},

    // FLAGS
    complexInstallation: null,
    isGettingBattery: null,

    // METHODS
    getBattery: function (cityId, vehicleId, leadSource) {
      if (self.isGettingBattery) {
        return;
      }

      self.isGettingBattery = true;

      var defer = $q.defer();

      var partner;

      if (leadSource) {
        partner = leadSource.toLowerCase();

        if (
          partner === 'adwords' ||
          partner === 'googlemerchant' ||
          partner === 'branding' ||
          partner === 'branding_balneariocomboriu' ||
          partner === 'branding_blumenau' ||
          partner === 'branding_brasilia' ||
          partner === 'branding_criciuma' ||
          partner === 'branding_curitiba' ||
          partner === 'branding_florianopolis' ||
          partner === 'branding_fortaleza' ||
          partner === 'branding_niteroi' ||
          partner === 'branding_recife' ||
          partner === 'branding_riodejaneiro' ||
          partner === 'branding_saojose' ||
          partner === 'branding_saoluis' ||
          partner === 'branding_saopaulo' ||
          partner === 'olx' ||
          partner === 'organico' ||
          partner === 'portal_v5' ||
          partner === 'portalbanner' ||
          partner === 'portalwidget' ||
          partner === 'mourafacil'
        ) {
          partner = '';
        }
      }

      getBatteryFactory
        .get({
          carro: vehicleId,
          cidade: cityId,
          partner: partner
        })
        .$promise
        .then(function (response) {
          if (response.alt) {
            if (response.alt.discount === 0) {
              response.alt.discountOffer = 0;
            } else {
              response.alt.discountOffer = response.alt.discount - 30;
            }

            if (response.alt.price === 0) {
              response.alt.priceOffer = 0;
            } else {
              response.alt.priceOffer = response.alt.price - 30;
            }
          }

          if (response.discount === 0) {
            response.discountOffer = 0;

            response.priceOffer = response.price - 30;
          } else {
            response.priceOffer = 0;

            response.discountOffer = response.discount - 30;
          }

          self.selectedBattery = response;

          self.isGettingBattery = false;

          defer.resolve();
        }, function (reason) {
          if (reason.data.error === 'No encontrado.') {
            toaster.pop('error', 'No hay precio registrado para esta ciudad.');
          }

          self.selectedBattery = {};

          $log.error('batteryService.getBattery', reason);

          self.isGettingBattery = false;
        });

      return defer.promise;
    },
    getBatteryWithModel: function (cityId, batteryModel, leadSource) {
      if (self.isGettingBattery) {
        return;
      }

      self.isGettingBattery = true;

      var defer = $q.defer();

      var partner;

      if (leadSource) {
        partner = leadSource.toLowerCase();

        if (
          partner === 'adwords' ||
          partner === 'googlemerchant' ||
          partner === 'branding' ||
          partner === 'branding_balneariocomboriu' ||
          partner === 'branding_blumenau' ||
          partner === 'branding_brasilia' ||
          partner === 'branding_criciuma' ||
          partner === 'branding_curitiba' ||
          partner === 'branding_florianopolis' ||
          partner === 'branding_fortaleza' ||
          partner === 'branding_niteroi' ||
          partner === 'branding_recife' ||
          partner === 'branding_riodejaneiro' ||
          partner === 'branding_saojose' ||
          partner === 'branding_saoluis' ||
          partner === 'branding_saopaulo' ||
          partner === 'olx' ||
          partner === 'organico' ||
          partner === 'portal_v5' ||
          partner === 'portalbanner' ||
          partner === 'portalwidget' ||
          partner === 'mourafacil'
        ) {
          partner = '';
        }
      }

      getBatteryFactory
        .get({
          modelo: batteryModel,
          cidade: cityId,
          partner: partner
        })
        .$promise
        .then(function (response) {
          if (response.alt) {
            if (response.alt.discount === 0) {
              response.alt.discountOffer = 0;
            } else {
              response.alt.discountOffer = response.alt.discount - 30;
            }

            if (response.alt.price === 0) {
              response.alt.priceOffer = 0;
            } else {
              response.alt.priceOffer = response.alt.price - 30;
            }
          }

          if (response.discount === 0) {
            response.discountOffer = 0;

            response.priceOffer = response.price - 30;
          } else {
            response.priceOffer = 0;

            response.discountOffer = response.discount - 30;
          }

          self.selectedBattery = response;

          self.isGettingBattery = false;

          defer.resolve();
        }, function (reason) {
          if (reason.data.error === 'No encontrado.') {
            toaster.pop('error', 'No hay precio registrado para esta ciudad.');
          }

          self.selectedBattery = {};

          $log.error('batteryService.getBattery', reason);

          self.isGettingBattery = false;
        });

      return defer.promise;
    }
  };

  return self;
}
