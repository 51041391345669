angular
  .module('app')
  .service('orderService', orderService);

/** @ngInject */
function orderService($rootScope, $log, $q, $window, toaster, orderFactory) {
  var self = {
    // OBJECTS
    orderData: {
      additionalInfo: '',
      address: {
        street: ''
      },
      attendant: {
        email: '',
        name: ''
      },
      batteryUseless: true,
      customer: {
        ssn: '',
        corporateName: '',
        taxDomicile: ''
      },
      origin: 'whatsapp',
      voucherUber: '',
      wantsInvoice: false
    },

    // STRINGS
    batteryBrand: 'moura',
    resume: '',

    // FLAGS
    isPlacingOrder: null,
    triedToOrder: null,

    // METHODS
    placeOrder: function (orderType) {
      if (self.isPlacingOrder) {
        return;
      }

      self.isPlacingOrder = true;

      var defer = $q.defer();

      self.orderData.attendant.email = $rootScope.attendantEmail;

      self.orderData.attendant.name = $rootScope.attendantName;

      if (self.batteryBrand === 'moura') {
        delete self.orderData.battery.alt;
      } else {
        self.orderData.battery = self.orderData.battery.alt;
      }

      if (self.orderData.assistenciaFacilPoc) {
        self.orderData.additionalInfo += 'Asistencia Ya\n';
      }

      if (!self.triedToOrder && self.orderData.battery.warranty) {
        self.orderData.additionalInfo += 'Garantizar: ' + self.orderData.battery.warranty + ' meses\n';

        if (self.orderData.leadSource === 'mapfre') {
          self.orderData.additionalInfo += 'Acción: MAPFRE\n';
        }
      }

      self.orderData.address.country = 'AR';

      if (!self.orderData.address.number) {
        self.orderData.address.number = 'S/N';
      }

      if (self.orderData.address.zipCode === '') {
        self.orderData.address.zipCode = null;
      }

      if (
        self.orderData.paymentType === 'Cash' ||
        self.orderData.paymentType === 'Transfer'
      ) {
        self.orderData.installments = null;
        self.orderData.creditCardBrand = null;
      }

      if (self.orderData.paymentType === 'DebitCard') {
        self.orderData.installments = null;
      }

      if (orderType === 'taller-amigo') {
        self.orderData.leadSource = 'talleramigo';
      }

      if (self.orderData.origin === 'mercadolivre') {
        self.orderData.partner = 'mercado_livre';
      }

      if (self.orderData.leadSource === 'mouramais') {
        self.orderData.partner = 'mouramais';
      }

      if (
        self.orderData.battery.model &&
        self.orderData.battery.model.startsWith('Z')
      ) {
        self.orderData.partner = 'zettabateria';
      }

      if (orderType === 'taller-amigo') {
        self.orderData.partner = 'talleramigo';
      }

      if (self.orderData.scheduled) {
        var sanitizedScheduleTime;
        var today = moment();
        var tomorrow = moment().add(1, 'd');

        if (self.orderData.city.zone && self.orderData.city.dst) {
          self.orderData.scheduleTime = moment(self.orderData.selectedScheduleDate.fulldate).tz('America/Recife');
        } else if (self.orderData.city.zone && !self.orderData.city.dst) {
          self.orderData.scheduleTime = moment(self.orderData.selectedScheduleDate.fulldate).tz('America/Manaus');
        } else if (!self.orderData.city.zone && self.orderData.city.dst) {
          self.orderData.scheduleTime = moment(self.orderData.selectedScheduleDate.fulldate).tz('Atlantic/South_Georgia');
        } else if (!self.orderData.city.zone && !self.orderData.city.dst) {
          self.orderData.scheduleTime = moment(self.orderData.selectedScheduleDate.fulldate).tz('America/Recife');
        }

        self.orderData.scheduleTime.hour(self.orderData.selectedScheduleTime.hour);
        self.orderData.scheduleTime.minute(self.orderData.selectedScheduleTime.minute);
        self.orderData.scheduleTime.second(0);

        if (typeof self.orderData.scheduleTime === 'string') {
          sanitizedScheduleTime = self.orderData.scheduleTime;
        } else if (typeof self.orderData.scheduleTime === 'object') {
          sanitizedScheduleTime = self.orderData.scheduleTime.toISOString();
        }

        self.orderData.scheduleTime = sanitizedScheduleTime.replace(/\.[0-9]+Z/, '-0000');

        if (
          moment(self.orderData.scheduleTime).isAfter(today, 'd') &&
          !moment(self.orderData.scheduleTime).isAfter(tomorrow, 'd')
        ) {
          self.orderData.scheduledDays = '1';
        } else if (moment(self.orderData.scheduleTime).isAfter(tomorrow, 'd')) {
          self.orderData.scheduledDays = '2';
        }
      } else {
        self.orderData.scheduleTime = null;
      }

      if (self.orderData.customer.ssn) {
        self.orderData.ssnOnReceipt = true;
      } else {
        self.orderData.ssnOnReceipt = false;
      }

      if (self.orderData.leadSource === 'flutuantedigital') {
        self.orderData.utmTesteAB = 'flutuante_dig';
      } else if (self.orderData.leadSource === 'flutuantetel') {
        self.orderData.utmTesteAB = 'flutuante_tel';
      }

      self.orderData.vehicleType = 'Car';

      var timeBeforeTheRequet = moment();

      orderFactory
        .save(self.orderData)
        .$promise
        .then(function (response) {
          self.orderData.assistenciaFacilPoc = response.assistenciaFacilPoc;

          if (response.voucherUber) {
            self.resume += 'Cupón Uber: ' + response.voucherUber + '\n\n';
          }

          if (response.alert) {
            self.resume += 'Alerta: ' + response.alert + '\n\n';
          }

          if (response.scheduleTime) {
            var orderDate = new Date(self.orderData.scheduleTime);

            self.resume += 'Entrega: Programada\n';

            self.resume += 'Día: ' + orderDate.toLocaleString('es-AR', { hour12: false }) + '\n\n';
          } else {
            self.resume += 'Entrega: Inmediata\n\n';
          }

          self.resume += 'Solicitud: ' + response.shortId + '\n';

          self.resume += 'Coche: ' + response.vehicle + '\n';

          self.resume += 'Batería: ' + response.battery.model + '\n';

          if (response.extraBattery) {
            self.resume += 'Batería adicional: ' + response.extraBattery + '\n';
          }

          if (
            (response.battery.cashPrice && response.paymentType === 'Cash') ||
            (response.battery.cashPrice && response.paymentType === 'DebitCard')
          ) {
            if (response.battery.cashDiscountPaymentType) {
              self.resume += 'Precio en ' + response.finalPrice + '\n';
            } else {
              self.resume += 'Precio de contado: $ ' + response.finalPrice + '\n';
            }
          } else if (response.battery.discount === 0) {
            self.resume += 'Precio de crédito: $ ' + response.finalPrice + '\n';
          } else {
            self.resume += 'Precio en crédito promocional: $ ' + response.battery.finalPrice + '\n';
          }

          if (response.paymentType === 'CreditCard') {
            self.resume += 'Forma de pago: Tarjeta de crédito\n';

            self.resume += 'Bandera: ' + response.creditCardBrand + '\n';

            self.resume += 'Cuotas: ' + response.installments + '\n\n';
          }

          if (response.paymentType === 'DebitCard') {
            self.resume += 'Forma de pago: Tarjeta de débito\n';

            self.resume += 'Bandera: ' + response.creditCardBrand + '\n\n';
          }

          if (response.paymentType === 'Cash') {
            self.resume += 'Forma de pago: Efectivo\n';

            self.resume += 'Intercambiar por: ' + response.change + '\n\n';
          }

          self.resume += 'Dirección: ' + response.address.street + ', ' + response.address.number + '\n';

          // self.resume += 'Vecindario: ' + response.address.neighborhood + '\n';

          self.resume += 'Ciudad: ' + response.address.city + ', ' + response.address.state + '\n';

          // self.resume += 'Código postal: ' + response.address.zipCode + '\n';

          if (response.address.complement) {
            self.resume += 'Complementar: ' + response.address.complement + '\n\n';
          } else {
            self.resume += '\n';
          }

          self.resume += 'Nombre: ' + response.customer.name + '\n';

          self.resume += 'Teléfono: ' + response.customer.phone;

          if (response.customer.email) {
            self.resume += '\nCorreo electrónico: ' + response.customer.email;
          }

          if (response.customer.ssn) {
            self.resume += '\nCPF: ' + response.customer.ssn;
          }

          if (response.customer.corporateName) {
            self.resume += '\nRazón social : ' + response.customer.corporateName;
          }

          if (response.customer.taxDomicile) {
            self.resume += '\nDomicilio fiscal : ' + response.customer.taxDomicile;
          }

          if (self.orderData.additionalInfo) {
            self.resume += '\n\nInformaciones adicionales: ' + self.orderData.additionalInfo;
          }

          if (self.orderData.partner === 'zettabateria') {
            self.resume += '\n\nCompañero: Zetta';
          }

          var hasEmail = false;
          var hasScheduleTime = false;
          var hasSSN = false;
          var orderCoordinates = response.address.latitude.toString() + ',' + response.address.longitude.toString();
          var orderExtraBattery = false;
          var orderPartner = false;
          var orderTotal = 0;

          if (response.customer.email) {
            hasEmail = true;
          }

          if (response.scheduleTime) {
            hasScheduleTime = true;
          }

          if (response.customer.ssn) {
            hasSSN = true;
          }

          if (response.extraBattery !== '') {
            orderExtraBattery = response.extraBattery;

            dataLayer.push({
              event: 'Extra battery'
            });
          }

          if (response.partner) {
            orderPartner = response.partner;
          }

          if (response.battery.discount > 0) {
            orderTotal = response.battery.discount;
          } else {
            orderTotal = response.battery.price;
          }

          if (response.battery.model.startsWith('Z')) {
            dataLayer.push({
              event: 'Alternative battery'
            });
          }

          if (
            response.car &&
            response.car.complex
          ) {
            dataLayer.push({
              event: 'Complex installation'
            });
          }

          dataLayer.push({
            event: 'transacaoTelefone',
            transactionAdWordsCity: false,
            transactionAddressCity: response.address.city,
            transactionAddressCityCoordinates: orderCoordinates,
            transactionAddressNeighborhood: response.address.neighborhood,
            transactionAddressState: response.address.state,
            transactionAddressStreet: response.address.street + ', ' + response.address.number,
            transactionAddressZipCode: response.address.zipCode,
            transactionAffiliation: 'Moura Ya Atendimento',
            transactionCustomerEmail: hasEmail,
            transactionCustomerName: response.customer.name,
            transactionCustomerPhone: response.customer.phone,
            transactionCustomerSSN: hasSSN,
            transactionId: response.shortId,
            transactionOrigin: response.origin,
            transactionPartner: orderPartner,
            transactionProducts: {
              category: 'Baterías Automotrices',
              charge: response.charge,
              installments: response.installments,
              name: response.battery.model,
              price: response.battery.price,
              quantity: 1,
              sku: response.battery.model
            },
            transactionProductsDiscount: response.battery.discount,
            transactionProductsExtraBattery: orderExtraBattery,
            transactionProductsName: response.battery.model,
            transactionProductsPaymentType: response.paymentType,
            transactionScheduled: hasScheduleTime,
            transactionShipping: 0,
            transactionTax: 0,
            transactionTotal: orderTotal,
            transactionVehicle: response.vehicle
          });

          // $window.Raven
          //   .setUserContext({
          //     email: localStorage.getItem('currentUserEmail')
          //   })
          //   .setExtraContext(self.orderData)
          //   .captureMessage('Finalizó la solicitud al back-end. Loading status: ' + self.isPlacingOrder, {
          //     sampleRate: 1,
          //     logger: 'orderService.placeOrder',
          //     level: 'info'
          //   });

          self.isPlacingOrder = false;
          self.triedToOrder = false;

          defer.resolve();
        }, function (reason) {
          var duration = moment.duration(moment().diff(timeBeforeTheRequet));
          var minutes = duration.asMinutes();

          $log.error('orderService.placeOrder', reason);

          $window.Raven
            .setUserContext({
              email: localStorage.getItem('currentUserEmail')
            })
            .setExtraContext(Object.assign(
              {
                timeOutErroMinutes: minutes
              },
              self.orderData
            ))
            .captureException(reason, {
              level: 'error',
              logger: 'orderService.placeOrder',
              sampleRate: 1
            });

          toaster.pop('error', '¡Ups! Inténtalo de nuevo.');

          self.isPlacingOrder = false;
          self.triedToOrder = true;
        });

      return defer.promise;
    },
    resetOrder: function (orderType) {
      self.orderData = {};

      self.orderData = {
        additionalInfo: '',
        attendant: {
          email: $rootScope.attendantEmail,
          name: $rootScope.attendantName
        },
        batteryUseless: true,
        creditCardBrand: orderType === 'moura-ya' ? 'MasterCard' : null,
        customer: {
          origin: '',
          ssn: '',
          corporateName: '',
          taxDomicile: ''
        },
        installments: 3,
        origin: 'whatsapp',
        partner: orderType === 'moura-ya' ? null : 'talleramigo',
        paymentType: orderType === 'moura-ya' ? 'CreditCard' : 'Cash',
        scheduled: true,
        voucherUber: ''
      };

      self.batteryBrand = 'moura';
    }
  };

  return self;
}
