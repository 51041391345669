angular
  .module('app')
  .factory('getTallerAmigoRetailersFactory', getTallerAmigoRetailersFactory);

/** @ngInject */
function getTallerAmigoRetailersFactory($resource, MOURA_SEARCH_API, MOURA_SEARCH_API_KEY) {
  return $resource(
    MOURA_SEARCH_API + '/revenda-taller/:retailerCode',
    {
      retailerCode: '@retailerCode'
    },
    {
      get: {
        method: 'GET',
        headers: {
          'x-api-key': MOURA_SEARCH_API_KEY
        },
        params: {
          cidade: '@cidade'
        }
      }
    }
  );
}
